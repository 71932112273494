import { CommonUtility } from './utility';
import { User } from './user';
import { Config } from './config';
import { ENV } from './proj_env';
import { API } from './api';
import { AnalyticHub } from './analytic';
import InfoDialogPlugin from '@/components/common/dialog/infoDialog';
import ToastPlugin from '@/components/common/toast/toast';
import VueLazyLoad from 'vue3-lazyload';
import { createPinia } from 'pinia';
import FeedbackDialogPlugin from '@/components/common/dialog/feedbackDialog';
import TxtToImgFeedbackDialogPlugin from '@/components/common/dialog/txteToImgfeedbackDialog';
import ReportFeedbackDialogPlugin from '@/components/common/dialog/reportFeedbackDialog';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

export default {
    install: (app) => {
        app.use(createPinia());
        app.use(InfoDialogPlugin);
        app.use(ToastPlugin);
        app.use(VueLazyLoad);
        app.use(FeedbackDialogPlugin);
        app.use(TxtToImgFeedbackDialogPlugin);
        app.use(ReportFeedbackDialogPlugin);
        app.use(ElementPlus);
        app.config.globalProperties.$utility = CommonUtility;
        app.config.globalProperties.$user = User.getInstance;
        app.config.globalProperties.$config = Config.getInstance;
        app.config.globalProperties.$env = ENV;
        app.config.globalProperties.$api = API;
        app.config.globalProperties.$analytic = AnalyticHub.getInstance;
        CommonUtility.init();
        AnalyticHub.initAnalytic();
        AnalyticHub.getInstance.log('guest_log_app', { time: '0' });

        window.addEventListener('message', event => {
            if (event.data === 'loadUserInfo') {
                User.getInstance.loadUserInfo();
            }
        });
    },
};
