<template>
    <div v-if="visible" class="dialog-overlay">
        <div class="dialog">

            <div class="title1">{{ $t("feedbackDialog.reportTitle") }}</div>
            <img class="cancle-btn" :src="assetImage('dialog_close')" @click="cancelDialog" />
            <div :style="space(30, 20)"></div>
            <div class="title2">{{ $t("feedbackDialog.reportOptionTitle") }}</div>
            <div :style="space(16, 10)"></div>
            <div class="wrap">
                <FyButton v-for="(item, index) in options" :key="index" class="itemBtn"
                    :status="item.selected ? 'highlight' : ''" @onClick="itemOptionClick(index)">
                    <p class="item-text">{{ item.msg }}</p>
                </FyButton>
            </div>
            <div :style="space(20, 20)"></div>
            <div class="title2">{{ $t("feedbackDialog.subTitle2") }}</div>
            <div style="height: 16px;"></div>
            <div class="textarea-container">
                <textarea class="textarea" v-model="message" :placeholder="$t('feedbackDialog.placeholder')">
                </textarea>
            </div>
            <div :style="space(30, 20)"></div>
            <FyButton :status="confirmBtnEnable ? 'highlight' : 'unable'" :supportHover="confirmBtnEnable"
                class="confirm-btn" @click="confirm">
                {{ $t("feedbackDialog.submit") }}
            </FyButton>
        </div>
    </div>
</template>

<script>

import { CommonUtility } from '@/script/utility';
import FyButton from '../button/FyButton.vue';
import { i18n } from '@/i18n';

export default {
    name: 'TxtToImgFeedbackDialog',
    components: { FyButton },
    data () {
        return {
            options: [
                { selected: false, msg: i18n.global.t('feedbackDialog.reportOption1') },
                { selected: false, msg: i18n.global.t('feedbackDialog.reportOption2') },
                { selected: false, msg: i18n.global.t('feedbackDialog.reportOption3') },
            ],
            visible: false,
            message: '',
            resolve: null,
            reject: null,
        };
    },
    computed: {
        confirmBtnEnable () {
            return this.message || this.options.some(item => item.selected);
        },

    },
    methods: {
        showDialog () {
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        space (large, narrow) {
            if (window.innerWidth > 560) {
                return `height:${large}px`;
            }
                return `height:${narrow}px`;
        },
        itemOptionClick (index) {
            this.options[index].selected = !this.options[index].selected;
        },
        assetImage (name) {
            return CommonUtility.assetImage(name);
        },
        confirm () {
            if (!this.confirmBtnEnable) {
                return;
            }
            let selectedArray = this.options.filter(item => item.selected).map(item => item.msg);
            this.resolve({ confirm: true, selectedArray, moreMsg: this.message });
            this.closeDialog();
        },
        cancelDialog () {
            this.resolve({ confirm: false });
            this.closeDialog();
        },
        closeDialog () {
            this.visible = false;
            this.message = '';
            for (const option of this.options) {
                option.selected = false;
            }
        },
    },
};
</script>

<style scoped lang="scss"> 
@import './common.css';

.title1 {
    width: 380px;
    height: 28px;
    font-family: 'PingFang SC';
    font-weight: 600;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    align-self: center;
}

.title2 {
    width: auto;
    height: 24px;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.95);
    line-height: 24px;
}

.confirm-btn {
    width: 330px;
    height: 45px;
    align-self: center;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.itemBtn {
    height: 30px;
}

.item-text {
    height: 14px;
    font-family: 'PingFang SC';
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 14px;
    margin: 8px 10px;
}

.textarea {
    width: 424px;
    height: 70px;
    font-family: 'PingFang SC';
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 14px;
    text-align: left;
    resize: none;
    background: transparent;
    box-sizing: border-box;
    border: 0;
}

.textarea:focus {
    outline: none;
}

.dialog {
    width: 520px;
    height: auto;
    background: #27282DFF;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 38px 20px 38px;
    display: flex;
    align-items: start;
    flex-direction: column;
    position: relative;

}

.wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
}

.textarea-container {
    width: 444px;
    height: 90px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    box-sizing: border-box;
    padding: 10px;
}

@media (max-width: 560px) {
    .dialog {
        width: 90%;
        height: auto;
        background: #27282DFF;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 20px 5% 20px 5%;
        display: flex;
        align-items: start;
        flex-direction: column;
    }

    .title1 {
        width: 258px;
        height: 22px;
        font-family: 'PingFang SC';
        font-weight: 600;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.95);
        text-align: center;
        align-self: center;
    }

    .cancle-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .itemBtn {
        height: 30px;
    }

    .textarea-container {
        width: 100%;
        height: 90px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        box-sizing: border-box;
        padding: 10px;
    }

    .textarea {
        width: 100%;
        height: 70px;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 14px;
        text-align: left;
        resize: none;
        background: transparent;
        box-sizing: border-box;
        border: 0;
    }

    textarea:focus {
        font-size: 16px;
        line-height: 18px;
    }

    .confirm-btn {
        width: 200px;
        height: 36px;
        align-self: center;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }

}
</style>
