import { createWebHistory, createRouter } from "vue-router";
import { i18n } from "@/i18n/index";
const routes = [
    {
        path: "/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/home/en.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/en/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/home/en.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/ja/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/home/ja.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/es/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/home/es.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/fr/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/home/fr.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/pt/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/home/pt.js")
            ).default;
            return import("./pages/home/index.vue");
        },
    },
    {
        path: "/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/en/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/fr/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/txtToImg/fr.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/es/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/txtToImg/es.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/pt/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/txtToImg/pt.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/ja/free-ai-image-generator/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/txtToImg/ja.js")
            ).default;
            return import("./pages/txtToImg/index.vue");
        },
    },
    {
        path: "/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/en/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/fr/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/txtToImg/fr.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/es/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/txtToImg/es.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/ja/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/txtToImg/ja.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/pt/free-ai-image-generator-faq/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/txtToImg/pt.js")
            ).default;
            return import("./pages/txtToImg/faq/index.vue");
        },
    },
    {
        path: "/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/en/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/txtToImg/en.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/fr/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/txtToImg/fr.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/es/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/txtToImg/es.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/ja/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/txtToImg/ja.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/pt/free-ai-image-generator-howToUse/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/txtToImg/pt.js")
            ).default;
            return import("./pages/txtToImg/howToUse/index.vue");
        },
    },
    {
        path: "/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/en/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/fr/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/es/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/ja/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/pt/video-swap-face-function/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/faceswap-faq/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/details/index.vue");
        },
    },
    {
        path: "/en/faceswap-faq/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/faq/index.vue");
        },
    },
    {
        path: "/fr/faceswap-faq/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/faq/index.vue");
        },
    },
    {
        path: "/es/faceswap-faq/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/faq/index.vue");
        },
    },
    {
        path: "/ja/faceswap-faq/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/faq/index.vue");
        },
    },
    {
        path: "/pt/faceswap-faq/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/faq/index.vue");
        },
    },
    {
        path: "/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/en/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/es/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/fr/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/ja/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/pt/how-video-face-swap-work/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/tutorial/index.vue");
        },
    },
    {
        path: "/photo-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/en/photo-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/fr/photo-face-swap/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/es/photo-face-swap/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/ja/photo-face-swap/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/pt/photo-face-swap/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/singleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/en/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/es/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/fr/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/ja/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/pt/multiple-picture-face-swap/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/multipleFacePhotoEdit/index.vue");
        },
    },
    {
        path: "/video-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/en/video-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/es/video-face-swap/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/fr/video-face-swap/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/ja/video-face-swap/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/pt/video-face-swap/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/singleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/en/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/faceSwap/en.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/es/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/faceSwap/es.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/fr/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/faceSwap/fr.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/ja/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/faceSwap/ja.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/pt/multiple-video-face-swap/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/faceSwap/pt.js")
            ).default;
            return import("./pages/faceSwap/multipleFaceVideoEdit/index.vue");
        },
    },
    {
        path: "/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/freeCredits/en.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/en/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/freeCredits/en.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/fr/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/freeCredits/fr.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/es/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/freeCredits/es.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/ja/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/freeCredits/ja.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/pt/ai-generator-invite/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/freeCredits/pt.js")
            ).default;
            return import("./pages/freeCredits/index.vue");
        },
    },
    {
        path: "/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/refer/en.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/en/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/refer/en.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/fr/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/refer/fr.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/es/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/refer/es.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/ja/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/refer/ja.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/pt/ai-image-generator-refer/:key",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/refer/pt.js")
            ).default;
            return import("./pages/refer/index.vue");
        },
    },
    {
        path: "/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/affiliate/en.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        path: "/en/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.en = (
                await import("./i18n/affiliate/en.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        path: "/fr/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.fr = (
                await import("./i18n/affiliate/fr.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        path: "/es/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.es = (
                await import("./i18n/affiliate/es.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        path: "/pt/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.pt = (
                await import("./i18n/affiliate/pt.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        path: "/ja/ai-video-face-swap-affiliate-program/",
        component: async () => {
            i18n.global.messages.ja = (
                await import("./i18n/affiliate/ja.js")
            ).default;
            return import("./pages/affiliate/index.vue");
        },
    },
    {
        // 捕获不存在页面
        path: '/:catchAll(.*)',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;