import { createApp } from 'vue';
import FeedbackDialog from './FeedbackDialog.vue';
import i18n from '@/i18n/index';

const FeedbackDialogPlugin = {

    install(app) {
        const DialogConstructor = createApp(FeedbackDialog);
        DialogConstructor.use(i18n);

        let instance;
        function showDialog(feedbackType = 'faceSwap') {
            if (!instance) {
                const container = document.createElement('div');
                document.body.appendChild(container);
                instance = DialogConstructor.mount(container);
            }
            return instance.showDialog(feedbackType);
        }
        app.config.globalProperties.$showFeedbackDialog = showDialog;
    },
};

export default FeedbackDialogPlugin;
