<template>
    <router-view />
</template>
<script setup>
import { onMounted } from 'vue';


onMounted(() => {
    const isPrerenderer = (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.foo == 'bar')
    if (isPrerenderer) {
        setTimeout(() => {
            document.dispatchEvent(new Event('render-event'));
        }, 3000)
    }
})

</script>
<style scoped lang="scss">
</style>