import { createI18n } from 'vue-i18n';
import { CommonUtility } from '@/script/utility';

let langs = [
    'en', 'fr', 'ja', 'es', 'pt',
];
let lang = 'en';
let lastPath = CommonUtility.getUrlRelativeLastComponentPath().toLowerCase();
if (langs.indexOf(lastPath) !== -1) {
    lang = lastPath;
}
export const i18n = createI18n({
    locale: lang,
    messages: {},
});


export default {
    install: (app) => {
        app.use(i18n);
        app.config.globalProperties.i18n = i18n;
        app.config.globalProperties.$jumpOtherPage = jumpOtherPage;
        app.config.globalProperties.$otherPageUrl = otherPageUrl;
    },
};

/*
跳转当前站点其它页面
path: 其它页面路由路径 (例：/otherPath)
newTab: 是否需要新开tab
matchI18n: 是否需要匹配i18n
*/
function jumpOtherPage(path, newTab, matchI18n) {
    let url = otherPageUrl(path, matchI18n);
    if (newTab) {
        window.open(url);
    } else {
        window.location.href = url;
    }
}
/*
跳转当前站点其它页面
path: 其它页面路由路径 (例：/otherPath)
matchI18n: 是否需要匹配i18n
*/
function otherPageUrl(path, matchI18n) {
    let url;
    if (matchI18n && i18n.global.locale !== 'en') {
        url = `${window.location.protocol}//${window.location.host}/${i18n.global.locale}${path}`;
    } else {
        url = `${window.location.protocol}//${window.location.host}${path}`;
    }
    return url;
}